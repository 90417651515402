@media all and (max-width: 767px) {
  .logoFix {
    width: 240px;
  }
}

@media all and (min-width: 768px) {
  .logoFix {
    width: 360px;
  }
}
