@import url('https://fonts.googleapis.com/css?family=Chivo:900|Lato:400,700,900|Lobster+Two:700&display=swap');

.red {
  border: 1px solid red;
}

body {
  margin: 0;
  padding-bottom: 100px;
  background-color: #fdff2e;
  color: black;
  letter-spacing: 0em;
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  margin-top: 5px;
  text-align: center;
  font-family: 'Lobster Two', cursive;
  font-weight: 700;
}

@media (max-width: 767.98px) {
  h1 {
    margin-top: 5px;
    text-align: center;
    font-size: 24px;
    font-family: 'Lobster Two', cursive;
    font-weight: 700;
  }
}

h2 {
  text-align: center;
  font-family: 'Lobster Two', cursive;
  font-size: 32px;
  font-weight: 700;
}

h3 {
  text-align: center;
  padding: 20px 0;
  color: #666666;
  font-size: 16px;
  font-weight: 700;
  background-color: #ffffff;
}

h4 {
  text-align: center;
  font-family: 'Lobster Two', cursive;
  font-size: 24px;
  font-weight: 700;
}

h5 {
  margin: 20px 0;
  text-align: center;
  font-size: 36px;
  font-family: 'Lobster Two', cursive;
  font-weight: 700;
}

.container {
  padding: 0 10px;
}

.row {
  margin: 0;
  background-color: #fdff2e;
}

/* HEADER */

.header {
  width: 100%;
  border-top: 4px solid #000000;
}

/* READOUT */

.readout {
  text-align: center;
  background-color: #fff341;
  border: 6px solid #000000;
}

.readout > .row {
  margin: 0;
  background-color: #fff341;
}

.readout p {
  text-align: center;
  font-size: 16px;
}

.readout-title {
  margin: 10px 0 10px 0;
  padding: 0;
  font-size: 36px;
  font-weight: 700;
}

@media (min-width: 768px) {
  .sticky {
    z-index: 1000;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }
}

.readout-title-order {
  margin: 10px 0 10px 0;
  padding: 0;
  text-align: center;
  font-size: 36px;
  font-weight: 700;
  /* border: 1px solid red;   */
}

/* @media (max-width: 767.98px) {
  .readout-title-order {
    text-align: center;
  }
} */

.cart {
  text-align: center;
  margin: 15px 0 10px 0;
  padding: 11px;
  color: #ffffff;
  font-size: 15px;
  font-weight: 700;
  letter-spacing: 0.1em;
  background-color: #231f20;
  /* border: 1px solid red;  */
}

@media (max-width: 767.98px) {
  .cart {
    margin: 0 0 10px 0;
  }
}

/* NAVS ... STRAIGHT FROM www */

#main_nav {
  text-align: center;
  font-size: 0.75em;
  font-weight: 700;
  position: relative;
  height: 5em;
  width: 100%;
  margin: 1.3em 0;
}

.nav-item-outer {
  position: absolute;
  top: 0;
}

.nav-item-inner {
  position: relative;
  width: 5em;
  height: 5em;
  cursor: pointer;
  -webkit-border-radius: 5em;
  -moz-border-radius: 5em;
  border-radius: 5em;
}

.nav-text {
  background-color: #fdff2e;
  width: 100%;
  position: relative;
  top: 1.75em;
  padding: 0.25em 0;
}

#nav_back {
  right: 0;
}

#nav_back .nav-item-inner {
  background-color: #050708;
}

/* @media (min-width: 576px) {
  .container > .header > img {
    margin: 0px auto;
    width: 400px;
    border:1px solid blue;
  }
}

@media (min-width: 768px) { 
  .container > .header > img {
    margin: 0px auto;
    width: 600px;
  }  
}

@media (min-width: 992px) { 
  .container > .header > img {
    margin: 0px auto;    
    width: 720px;
  }
}

@media (min-width: 1200px) { 
  .container > .header > img {
    margin: 0px auto;    
    width: 800px;
  }
} */

/* MENU */

.menu {
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: #ffffff;
  color: #000000;
  border: 4px solid black;
}

.item {
  margin: 0 -13px 0px -13px;
  padding: 20px 0;
  border-top: 3px dotted #000000;
  background-color: #ffffff;
}

.item-even {
  margin: 0 -13px 0px -13px;
  padding: 20px 0;
  background-color: #fffbf2;
  border-top: 3px dotted #000000;
}

.item-top {
  margin: 0 -13px 0px -13px;
  padding: 20px 0;
  background-color: #ffffff;
}

.item p {
  padding: 0 40px;
}

.item-title {
  margin: 0;
  color: #000000;
  font-size: 18px;
  font-weight: 700;
}

.item-description {
  margin: 0 0 20px 0;
  color: #000000;
  font-size: 16px;
}

.item-price {
  margin: 4px 0 10px 0;
  padding: 5px 0 0 0;
  text-align: right;
  font-size: 20px;
  font-family: 'Chivo', sans-serif;
  font-weight: 900;
  color: #333333;
}

.item-price-cart {
  margin: 4px 0 40px 0;
  padding: 5px 0 0 0;
  text-align: right;
  font-size: 20px;
  font-family: 'Chivo', sans-serif;
  font-weight: 900;
  color: #333333;
}

.item-serving {
  margin: 0 10px 0 0;
  color: #333333;
  font-size: 13px;
  font-weight: 700;
}

.item-quantity {
  justify-content: center;
  margin-top: 5px;
  padding: 2px;
}

.item-quantity input[type='text'],
select,
textarea {
  height: 36px;
  width: 60px;
  text-align: center;
  font-weight: 700;
  border-radius: 0;
  border: 2px solid #000000;
}

.item-quantity button {
  padding: 0;
  background-color: #231f20;
  font-size: 20px;
  font-weight: 700;
  width: 30px;
  border-radius: 0;
  border: none;
}

.drinks {
  margin: 0 0 30px 0;
}

.menu-border:after {
  content: ''; /* This is necessary for the pseudo element to work. */
  display: block; /* This will put the pseudo element on its own line. */
  margin: 0 auto; /* This will center the border. */
  width: 85%; /* Change this to whatever width you want. */
  padding-top: 10px; /* This creates some space between the element and the border. */
  border-bottom: 1px solid #999; /* This creates the border. Replace black with whatever color you want. */
}

/* PICK-UP */

.pick-up {
  margin: 0;
  padding: 0;
  background-color: #ffffff;
  text-align: center;
}

.pick-up input[type='text'],
input[type='date'],
input[type='time'],
select,
textarea {
  padding: 10px;
  height: 40px;
  font-weight: 700;
  border-radius: 0;
  border: 2px solid #000000;
}

.pick-up select {
  height: 40px;
  font-weight: 700 !important;
  border-radius: 0 !important;
  border: 2px solid #000000 !important;
}

.pick-up label {
  margin-bottom: 2px;
  padding: 0;
  color: #666666;
  font-size: 18px;
  font-weight: 700;
}

/* REMOVE ITEM */

.remove-item {
  text-align: center;
  margin-top: 5px;
  margin-bottom: 5px;
}

.remove-item button {
  color: #333333;
  font-size: 14px;
}

/* SPECIAL INSTRUCTIONS */

.order-special-instructions {
  margin: 0 -13px 0px -13px;
  padding: 20px 0;
  border-top: 3px dotted #000000;
  background-color: #ffffff;
}

.order-special-instructions-input {
  margin-top: 2px;
  padding: 10px;
  resize: none;
  font-weight: 700;
  text-align: left;
  border-radius: 0;
  border: 2px solid #000000;
}

.delivery-special-instructions {
  margin: 0 -13px 0px -13px;
  padding: 20px 0;
  border-top: 3px dotted #000000;
  background-color: #fffbf2;
}

.delivery-special-instructions-input {
  padding: 10px;
  resize: none;
  font-weight: 700;
  text-align: left;
  border-radius: 0;
  border: 2px solid #000000;
}

/* ORDER-TOTAL */

.order-total {
  text-align: center;
}

.order-total-title {
  margin: 10px 0 0 0;
  color: #333333;
  font-size: 20px;
  font-weight: 700;
}

.order-total-amount {
  margin: 5px 0;
  padding: 10px 20px;
  background-color: #ffffff;
  font-size: 24px;
  font-weight: 700;
  border-radius: 0;
  border: 4px solid #000000;
}

.order-quantity-update {
  margin: -20px 0 0 0;
  text-align: center;
  color: #999999;
  font-size: 14px;
  font-weight: 700;
}

/* CHECKOUT */

.checkout {
  margin: 0 -13px 0px -13px;
  padding: 20px 0;
  background-color: #ffffff;
}

.checkout-even {
  margin: 0 -13px 0px -13px;
  padding: 20px 0;
  background-color: #fffbf2;
}

.checkout input[type='text'],
select,
textarea,
.checkout-even input[type='text'],
select,
textarea {
  padding: 10px;
  height: 48px;
  width: 100%;
  font-weight: 700;
  /* background: #ffffff; */
  border-radius: 0;
  border: 2px solid #000000;
}

@media (max-width: 767.98px) {
  .col-padding-top {
    padding-top: 20px;
  }

  .col-padding-bottom {
    padding-bottom: 20px;
  }
}

.checkout button,
.checkout-even button {
  padding: 0 10px;
  background-color: #231f20;
  font-size: 14px;
  font-weight: 700;
  border-radius: 0;
  border: none;
}

.checkout-item-title {
  margin: 0 0 10px 0;
  color: #000000;
  font-size: 18px;
  font-weight: 700;
}

/* PAYMENT DECLINED */

.payment-declined {
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  color: #dc3545;
}

/* SELECT NEW TIME */

.select-new-time {
  margin: 0 -13px 0px -13px;
  padding: 20px 0;
  background-color: #ffffff;
}

.select-new-time .row {
  background-color: #ffffff;
}

.select-new-time p {
  text-align: center;
  font-size: 18px;
  font-weight: 700;
}

.select-new-time button {
  margin: 10px 0;
  padding: 10px;
  color: #ffffff;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.1em;
  border-radius: 0;
  background-color: #231f20;
}

.no-times-available {
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  color: #dc3545;
}

/* ORDER-NUMBER */

.order-number {
  margin: 0 0 40px 0;
  text-align: center;
}

.order-number-title {
  margin: 10px 0 0 0;
  color: #666666;
  font-size: 20px;
  font-weight: 700;
}

.order-number-readout {
  margin: 5px 20px;
  padding: 10px 20px;
  background-color: #ffffff;
  font-size: 36px;
  font-weight: 700;
  border-radius: 0;
  border: 4px solid #000000;
}

/* FOOTER */

.footer {
  z-index: 1000;
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
}

.footer-button {
  background-color: #fff341;
  border: 6px solid #000000;
}

.footer-button > .row {
  margin: 0;
  background-color: #fff341;
}

.footer-button button {
  margin: 10px 0;
  padding: 10px;
  color: #ffffff;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.1em;
  border-radius: 0;
  background-color: #231f20;
}

.footer-button-back button {
  margin: 10px 0;
  padding: 10px;
  color: #333333;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.1em;
  border: none;
  background-color: #fff341;
}

/* ADMIN */

.admin h1 {
  margin-bottom: 20px;
  padding: 0 0 0 60px;
  text-align: center;
  font-family: 'Lobster Two', cursive;
  font-weight: 700;
}

.admin button {
  float: right;
  padding: 10px 20px;
  background-color: #231f20;
  font-size: 18px;
  font-weight: 700;
  border-radius: 0;
  border: none;
}

.admin-tab button {
  float: left;
  text-align: center;
  padding: 12px 20px 8px 20px;
  color: #666666;
  background-color: #e7e7e7;
  font-size: 18px;
  font-weight: 700;
  border-radius: 0;
  border-top: 2px solid #fdff2e;
  border-right: none;
  border-left: none;
  border-bottom: 1px solid #e7e7e7;
}

.admin-tab-on button {
  float: left;
  text-align: center;
  margin-right: 20px;
  padding: 10px 20px;
  color: #231f20;
  background-color: #ffffff;
  font-size: 18px;
  font-weight: 700;
  border-radius: 0;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 3px solid #ffffff;
}

.admin-order {
  margin-bottom: 20px;
  background-color: #ffffff;
  color: #000000;
}

.admin-order-item {
  margin: 10px -13px 0 -13px;
  padding: 20px 0 0 40px;
  border-top: 1px dotted #000000;
  font-size: 24px;
  font-weight: 700;
}

.admin-order-serving {
  padding: 5px 20px 0 0;
  color: #333333;
  font-size: 20px;
  font-weight: 700;
}

.admin-order-stats {
  margin: 10px -13px 0 -13px;
  padding: 20px 0;
  background-color: #fffbf2;
  border-top: 1px dotted #000000;
  text-align: center;
}

.admin-order-stats-title {
  margin: 10px 0 0 0;
  color: #666666;
  font-size: 16px;
  font-weight: 700;
}

.admin-order-stats-readout {
  margin: 5px 20px;
  padding: 10px 10px;
  background-color: #ffffff;
  font-size: 18px;
  font-weight: 700;
  border-radius: 0;
  border: 4px solid #000000;
}

.admin-order-show {
  margin: 0 -13px 0 -13px;
  padding: 20px 0;
  background-color: #fffbf2;
  text-align: center;
}

.admin-order-footer {
  text-align: center;
  margin: 20px;
  padding: 20px;
  border: 2px solid #000000;
}

.admin-order-footer button {
  padding: 10px 20px;
  background-color: #231f20;
  font-size: 18px;
  font-weight: 700;
  border-radius: 0;
  border: none;
}

/* ORDER TYPE */

.order-type {
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: #ffffff;
  color: #000000;
  border: 4px solid black;
  text-align: center;
}

.order-type button {
  margin: 10px;
  padding: 10px;
  color: #ffffff;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.1em;
  border-radius: 0;
  background-color: #231f20;
}

.order-type > .row {
  margin: 0;
  background-color: #ffffff;
}
